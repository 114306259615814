export const listSchools = /* GraphQL */ `
    query ListSchools(
        $id: ID
        $filter: ModelSchoolFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listSchools(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                slug
                beds
                name {
                    legal
                    popular
                }
                avatar {
                    size
                    name
                    src
                }
                district {
                    id
                    slug
                    name {
                        legal
                        popular
                    }
                }
                state {
                    enabled
                }
                createdAt
                updatedAt
                districtSchoolsId
            }
            nextToken
        }
    }
`;
export const listUserSchools = /* GraphQL */ `
    query ListUserSchools(
        $filter: ModelUserSchoolsFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listUserSchools(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                user {
                    id
                    username
                    name {
                        first
                        last
                    }
                    avatar {
                        size
                        name
                        src
                    }
                }
                school {
                    name {
                        legal
                    }
                }
            }
            nextToken
        }
    }
`;
export const updateSchool = /* GraphQL */ `
    mutation UpdateSchool(
        $input: UpdateSchoolInput!
        $condition: ModelSchoolConditionInput
    ) {
        updateSchool(input: $input, condition: $condition) {
            id
            slug
            name {
                legal
                popular
            }
            avatar {
                size
                name
                src
            }
            district {
                id
                slug
                name {
                    legal
                    popular
                }
            }
            state {
                enabled
            }
            createdAt
            updatedAt
            districtSchoolsId
        }
    }
`;
export const onCreateSchool = /* GraphQL */ `
    subscription OnCreateSchool {
        onCreateSchool {
            id
            slug
            name {
                legal
                popular
            }
            avatar {
                size
                name
                src
            }
            district {
                id
                slug
                name {
                    legal
                    popular
                }
            }
            state {
                enabled
            }
            createdAt
            updatedAt
            districtSchoolsId
        }
    }
`;
export const onUpdateSchool = /* GraphQL */ `
    subscription OnUpdateSchool {
        onUpdateSchool {
            id
            slug
            name {
                legal
                popular
            }
            avatar {
                size
                name
                src
            }
            district {
                id
                slug
                name {
                    legal
                    popular
                }
            }
            state {
                enabled
            }
            createdAt
            updatedAt
            districtSchoolsId
        }
    }
`;
export const onDeleteSchool = /* GraphQL */ `
    subscription OnDeleteSchool {
        onDeleteSchool {
            id
            slug
            name {
                legal
                popular
            }
            avatar {
                size
                name
                src
            }
            district {
                id
                slug
                name {
                    legal
                    popular
                }
            }
            state {
                enabled
            }
            createdAt
            updatedAt
            districtSchoolsId
        }
    }
`;
