export const listDistricts = /* GraphQL */ `
    query ListDistricts(
        $id: ID
        $filter: ModelDistrictFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listDistricts(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name {
                    legal
                    popular
                }
                districtZoneId
                state {
                    enabled
                }
            }
            nextToken
        }
    }
`;

export const onUpdateDistrict = /* GraphQL */ `
    subscription OnUpdateDistrict {
        onUpdateDistrict {
            id
            name {
                legal
                popular
            }
            zone {
                id
                name
                counties
            }
            state {
                enabled
            }
        }
    }
`;

export const listZones = /* GraphQL */ `
    query ListZones(
        $id: ID
        $filter: ModelZoneFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listZones(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name
                counties
                state {
                    enabled
                }
            }
            nextToken
        }
    }
`;

export const createSchool = /* GraphQL */ `
    mutation CreateSchool(
        $input: CreateSchoolInput!
        $condition: ModelSchoolConditionInput
    ) {
        createSchool(input: $input, condition: $condition) {
            id
            slug
            name {
                legal
                popular
            }
            avatar {
                size
                name
                src
            }
            district {
                id
                slug
                name {
                    legal
                    popular
                }
            }
            state {
                enabled
            }
            createdAt
            updatedAt
            districtSchoolsId
        }
    }
`;
