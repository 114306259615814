/* eslint-disable no-await-in-loop */
import {API, graphqlOperation} from 'aws-amplify';
import {batchProcessWithRetry, SwalData} from '@/graphql/cascade/batch';

export const cascadeConfirmDeleteOptions = {
    confirm: {
        text: `
          <p class='mb-0'>Deleting this School may have unwanted side effects.</p>
          <p class='font-small-3 text-danger'>
            Teachers & Students who are currently assigned to this school will have their association removed.<br/>
            This may effect applications, selections, acceptance, as well as reports.
          </p>
          <strong>Be very careful when performing this action.</strong>
        `,
        shouldParse: true
    },
    confirmed: { text: 'School was successfully deleted.' }
}

export async function cascadeDeleteSchool(schoolId, swalCallback) {
    try {
        /** Delete User-School records associated to this School **/
        const userSchools = await listUserSchools(schoolId, swalCallback)
        await batchProcessWithRetry(userSchools, async (userSchool) => {
            await API.graphql(graphqlOperation(deleteUserSchoolsMutation, { input: { id: userSchool.id }} ));
        }, { title: 'Deleting User Schools', callback: swalCallback })

        await new Promise(resolve => setTimeout(resolve, 1000));

        /** Update Student records associated to this School **/
        const students = await listStudentsBySchool(schoolId, swalCallback)
        await batchProcessWithRetry(students, async (student) => {
            await API.graphql(graphqlOperation(updateStudentMutation, { input: { id: student.id, schoolID: null }} ));
        }, { title: 'Updating Students', callback: swalCallback })

        /** Delete School **/
        await deleteSchool(schoolId, swalCallback)
    }
    catch(e) {
        console.error(e)
    }
}

/** Helper Functions **/
async function deleteSchool(schoolId, swalCallback) {
    const title = 'Deleting School'
    swalCallback(new SwalData(title, 0, 1))
    await new Promise(resolve => setTimeout(resolve, 500));
    await API.graphql(graphqlOperation(deleteSchoolMutation, { input: { id: schoolId }} ));
    swalCallback(new SwalData(title, 1, 1))
    await new Promise(resolve => setTimeout(resolve, 500));
}

async function listUserSchools(schoolId, swalCallback) {
    const items = []
    let nextToken = null;
    do {
        try {
            const input = { limit: 250, nextToken: nextToken, filter: { schoolID: { eq: schoolId } }}
            const response = await API.graphql(graphqlOperation(listUserSchoolsQuery, input));
            items.push(...response.data.listUserSchools.items)
            nextToken = response.data.listUserSchools.nextToken;
            swalCallback(new SwalData('Loading User Schools', items.length, items.length))
        }
        catch (error) {
            console.error(error);
            break;
        }
    }
    while (nextToken);
    await new Promise(resolve => setTimeout(resolve, 1000));
    return items
}

async function listStudentsBySchool(schoolId, swalCallback) {
    const items = []
    let nextToken = null;
    do {
        try {
            const input = { limit: 250, nextToken: nextToken, schoolID: schoolId }
            const response = await API.graphql(graphqlOperation(listStudentsBySchoolQuery, input));
            items.push(...response.data.listStudentsBySchool.items)
            nextToken = response.data.listStudentsBySchool.nextToken;
            swalCallback(new SwalData('Loading Students', items.length, items.length))
        }
        catch (error) {
            console.error(error);
            break;
        }
    }
    while (nextToken);
    await new Promise(resolve => setTimeout(resolve, 1000));
    return items
}

/** Queries & Mutations **/
const listUserSchoolsQuery = /* GraphQL */ `
    query ListUserSchools(
        $filter: ModelUserSchoolsFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listUserSchools(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
            }
            nextToken
        }
    }
`;

const listStudentsBySchoolQuery = /* GraphQL */ `
    query ListStudentsBySchool(
        $schoolID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelStudentFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listStudentsBySchool(
            schoolID: $schoolID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
            }
            nextToken
        }
    }
`;

const deleteSchoolMutation = /* GraphQL */ `
    mutation DeleteSchool(
        $input: DeleteSchoolInput!
        $condition: ModelSchoolConditionInput
    ) {
        deleteSchool(input: $input, condition: $condition) {
            id
        }
    }
`;

const deleteUserSchoolsMutation = /* GraphQL */ `
    mutation DeleteUserSchools(
        $input: DeleteUserSchoolsInput!
        $condition: ModelUserSchoolsConditionInput
    ) {
        deleteUserSchools(input: $input, condition: $condition) {
            id
        }
    }
`;

const updateStudentMutation = /* GraphQL */ `
    mutation UpdateStudent(
        $input: UpdateStudentInput!
        $condition: ModelStudentConditionInput
    ) {
        updateStudent(input: $input, condition: $condition) {
            id
        }
    }
`;
