<template>
  <page-layout ref="layout">
    <template #actions="{ }">
      <can do="create" on="management-schools">
        <b-button v-b-tooltip="'Create'" v-b-modal="'school-modal'" variant="transparent" size="sm">
          <font-awesome-icon icon="fa-solid fa-plus" />
        </b-button>
        <school-modal id="school-modal" @created="addSchool"></school-modal>
      </can>
    </template>

    <template #dropdown-options="">
      <b-dropdown-item @click="refresh">
        <font-awesome-icon icon="fa-solid fa-rotate-right"></font-awesome-icon>
        <span class="align-middle ml-50">Refresh</span>
      </b-dropdown-item>
    </template>

    <template #content="{ state }">
        <table-layout ref="table-layout"
                      :items="table.items"
                      :loading="table.loading"
                      :fields="table.fields"
                      :filters="table.filters" :filters-options="{ visible: true, collapsed: true }"
                      :sorting="table.sorting"
                      :subscriptions="table.subscriptions"
                      :func-delete="deleteSchool"
                      :export-exclude-fields="[
                        'id',
                        'slug',
                        'avatar.size',
                        'avatar.src',
                        'avatar.name',
                        'district.id',
                        'district.slug',
                        'districtSchoolsId',
                        'state.enabled',
                      ]"
                      @mounted="table = $event"
                      @updated="table = $event">

          <template #overlay>
            <overlay-loading :items="[
            { state: table.loading, desc: 'Loading Schools', loaded: table.loaded},
            { state: state.loading, desc: 'Rendering Template'},
          ]" />
          </template>

          <!-- Filters -->
          <template #filters>
            <b-row>
              <b-col>
                <b-form-group label="District" label-for="district-input">
                  <v-select id="district-input"
                            v-model="table.filters.district.value"
                            :options="filterDistrictOptions"
                            :select-on-tab="true"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            class="w-100"/>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Enabled" label-for="enabled-input">
                  <v-select id="enabled-input"
                            v-model="table.filters.enabled.value"
                            :options="['true', 'false']"
                            :searchable="false"
                            :select-on-tab="true"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            class="w-100"/>
                </b-form-group>
              </b-col>
            </b-row>
          </template>

          <!-- Column: Name -->
          <template #cell(name)="{data}">
            <b-media vertical-align="center" no-body>
              <b-media-aside>
                <b-avatar v-if="hasCustomAvatar(data.item.avatar)" v-b-modal.avatar-modal variant="primary" size="2.5em" badge-variant="white">
                  <b-img fluid fluid-grow :src="data.item.avatar.src" :alt="data.item.avatar.name"></b-img>
                </b-avatar>
                <b-avatar v-else v-b-modal.avatar-modal variant="primary" size="2.5em" badge-variant="white">
                  <font-awesome-icon :icon="icon"></font-awesome-icon>
                </b-avatar>
              </b-media-aside>
              <b-media-body class="align-self-center">
                <b-link :to="{ name: 'management-school', params: { id: data.item.id, slug: data.item.slug } }" class="font-weight-bold d-block text-nowrap">
                  <span class="d-block">{{ data.item.name.legal }}</span>
                </b-link>
                <small class="d-block">{{ data.item.name.popular }}</small>
              </b-media-body>
            </b-media>
          </template>

          <template #cell(district.name)="{data}">
            <template v-if="data.item.district">
              <span class="d-block">{{ data.item.district.name.legal }}</span>
              <small class="d-block">{{ data.item.district.name.popular }}</small>
            </template>
          </template>

          <!-- Column: State - Enabled -->
          <template #cell(state.enabled)="{data}">
            <b-badge pill :variant="`light-${data.item.state.enabled ? 'success' : 'danger'}`" class="text-capitalize">
              {{ data.item.state.enabled }}
            </b-badge>
          </template>

          <!-- Column: Actions -->
          <template #cell(row-options)="{data}">
            <b-dropdown-item :to="{ name: 'management-school', params: { id: data.item.id, slug: data.item.slug } }"
                             class="table-row-option-view">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">View</span>
            </b-dropdown-item>

            <template v-if="$can('update', 'management-schools') || $can('delete', 'management-schools')">
              <b-dropdown-divider/>
              <can do="update" on="management-schools">
                <b-dropdown-item class="table-row-option-enable"
                                 @click="updateSchool(data.item)">
                  <font-awesome-icon :icon="['fas', data.item.state.enabled ? 'toggle-off' : 'toggle-on']"></font-awesome-icon>
                  <span class="align-middle ml-50">{{ data.item.state.enabled ? 'Disable' : 'Enable' }}</span>
                </b-dropdown-item>
              </can>
              <can do="delete" on="management-schools">
                <b-dropdown-item class="table-row-option-delete"
                                 @click="$refs.layout.confirmDelete(data.item, deleteSchool, cascadeConfirmDeleteOptions)">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
              </can>
            </template>
          </template>
        </table-layout>
    </template>

    <template #debug>
      <debug :collapsed="true">{{ table }}</debug>
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/PageLayout.vue';
import TableLayout from '@/components/TableLayout.vue';
import OverlayLoading from '@/components/OverlayLoading.vue';
import SchoolModal from '@/views/management/schools/SchoolModal.vue'
import avatar from '@/mixins/avatar.mixin';
import notify from '@/mixins/notify.mixin';
import print from '@/mixins/print.mixin';
import vSelect from 'vue-select'
import { API, graphqlOperation } from 'aws-amplify';
import { listSchools, updateSchool, onCreateSchool, onUpdateSchool, onDeleteSchool} from './queries/schools';
import { cascadeDeleteSchool, cascadeConfirmDeleteOptions} from '@/graphql/cascade/school';

export default {
  name: 'Schools',
  components: {
    OverlayLoading,
    PageLayout,
    TableLayout,
    SchoolModal,
    vSelect
  },
  mixins: [ avatar, notify, print ],
  data() {
    return {
      table: {
        items: [],
        loading: true,
        fields: [
          {
            key: 'name',
            subKeys: ['name.legal', 'name.popular'],
            label: 'Name',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'beds',
            label: 'BEDS',
            sortable: true,
            filterable: true,
            visible: false,
            tdClass: 'align-middle'
          },
          {
            key: 'district.name',
            subKeys: ['district.name.legal', 'district.name.popular'],
            label: 'District',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'state.enabled',
            label: 'Enabled',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'row-options',
            label: '',
            sortable: false,
            filterable: false,
            visible: true,
            tdClass: ['align-middle', 'table-row-options']
          },
        ],
        filters: {
          district: { key: 'district.name.legal', value: null },
          enabled: { key: 'state.enabled', value: null }
        },
        sorting: {
          by: 'name.legal',
          desc: false
        },
        subscriptions: {
          onCreate: null,
          onUpdate: null,
          onDelete: null
        }
      },
      icon: 'fas fa-school',
      debug: { },
      cascadeConfirmDeleteOptions
    }
  },
  computed: {
    filterDistrictOptions() {
      return [...new Set(this.table.items.map(item => item.district?.name?.legal || null).filter(name => name !== null))].sort((a, b) => a.localeCompare(b))
    },
    filterEnabledOptions() {
      return [{ label: 'true', value: true }, { label: 'false', value: 'false' }]
    }
  },
  async created() {
    await this.listSchools();
    this.onCreateSchool();
    this.onUpdateSchool();
    this.onDeleteSchool();
  },
  mounted() {
    this.$refs.layout.state.loading = false
  },
  methods: {
    /** Table Data **/
    async listSchools(nextToken, pagedSchools) {
      this.table.loading = true
      const schools = pagedSchools || []
      const response = await API.graphql(graphqlOperation(listSchools, { limit: 500, nextToken: nextToken }));
      schools.push(...response.data.listSchools.items);

      this.table.loaded = schools.length

      if(response.data.listSchools.nextToken) {
        await this.listSchools(response.data.listSchools.nextToken, schools)
      }
      else {
        this.table.items = schools
        this.table.loading = false
      }
    },
    async addSchool(school) {
      this.table.loading = true
      this.table.items.push(school)
      this.table.loading = false
    },
    async updateSchool(school) {
      try {
        school.state.enabled = !school.state.enabled
        await API.graphql(graphqlOperation(updateSchool, { input: { id: school.id, state: school.state } }));
        this.notify({ title: 'Success', text: 'School was successfully updated', icon: this.icon, variant: 'success' });
      }
      catch (error) {
        console.error(error)
        this.notify({ title: 'Error', text: 'School failed to update', icon: this.icon, variant: 'danger' });
      }
    },
    async deleteSchool(school, swalCallback) {
      try {
        this.table.loading = true
        await this.cascadeDeleteSchool(school.id, swalCallback)
        this.table.items = this.table.items.filter(item => item.id !== school.id);
        await this.notify({ title: 'Success', text: 'School was successfully deleted', icon: this.icon, variant: 'success' });
      }
      catch (error) {
        console.error(error)
        this.notify({ title: 'Error', text: 'School failed to delete', icon: this.icon, variant: 'danger' });
        throw error //for Swal
      }
      finally {
        this.table.loading = false
      }
    },
    cascadeDeleteSchool,

    /** Subscriptions **/
    onCreateSchool() {
      this.table.subscriptions.onCreate = API.graphql(graphqlOperation(onCreateSchool)).subscribe((sourceData) => {
        const school = sourceData.value.data.onCreateSchool
        if (school && !this.table.items.map(item => item.id).includes(school.id)) {
          this.syncNotification()
          this.table.loading = true
          this.table.items = [school, ...this.table.items];
          this.table.loading = false
        }
      });
    },
    onUpdateSchool() {
      this.table.subscriptions.onUpdate = API.graphql(graphqlOperation(onUpdateSchool)).subscribe((sourceData) => {
        const school = sourceData.value.data.onUpdateSchool
        if (school && this.table.items.map(item => item.id).includes(school.id)) {
          this.syncNotification()
          this.table.loading = true
          const index = this.table.items.findIndex(item => item.id === school.id)
          if(index > -1) {
            this.table.items.splice(index, 1, school)
          }
          this.table.loading = false
        }
      });
    },
    onDeleteSchool() {
      this.table.subscriptions.onDelete = API.graphql(graphqlOperation(onDeleteSchool)).subscribe((sourceData) => {
        const school = sourceData.value.data.onDeleteSchool
        if(school && this.table.items.map(item => item.id).includes(school.id)) {
          this.syncNotification()
          this.table.loading = true
          this.table.items = this.table.items.filter(item => item.id !== school.id);
          this.table.loading = false
        }
      });
    },

    /** Actions **/
    async refresh() {
      await this.listSchools()
    },
  }
}
</script>

<style lang="scss">

</style>
