<template>
  <b-modal :id="id" centered size="xl" title="New School" no-close-on-backdrop footer-class="d-block" @hidden="clear()">
    <!-- Content -->
    <template #default>
      <b-container>

        <b-alert show variant="primary" class="my-2">
          <b-icon icon="info-circle" class="mr-2"/>Additional information can be managed after the creation of a new school.
        </b-alert>

        <validation-observer ref="observer" v-slot="{ handleSubmit }" tag="div">
          <form ref="form" @submit.stop.prevent="handleSubmit(create)">
            <b-row>
              <b-col cols="auto">
                <b-form-group label="Type" label-for="is-public">
                  <b-checkbox v-model="school.isPublic"
                              button button-variant="outline-primary" @input="onIsPublicInput">
                    {{ school.isPublic ? 'Public' : 'Non-Public' }}
                  </b-checkbox>
                </b-form-group>
              </b-col>
              <b-col>
                <validation-provider v-slot="validationContext" vid="name-legal" name="Legal Name" rules="required">
                  <b-form-group label="Legal Name" label-for="name-legal" :invalid-feedback="validationContext.errors[0]">
                    <b-form-input id="name-legal" v-model="school.name.legal" :state="getValidationState(validationContext)"></b-form-input>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col>
                <validation-provider v-slot="validationContext" vid="name-popular" name="Popular Name" rules="required">
                  <b-form-group label="Popular Name" label-for="name-popular" :invalid-feedback="validationContext.errors[0]">
                    <b-form-input id="name-popular" v-model="school.name.popular" :state="getValidationState(validationContext)"></b-form-input>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col>
                <validation-provider v-slot="validationContext" vid="beds" name="BEDS Code" :rules="rules.school.beds">
                  <b-form-group label="BEDS Code" label-for="beds" :invalid-feedback="validationContext.errors[0]">
                    <b-form-input id="beds" v-model="school.beds" :state="getValidationState(validationContext)"></b-form-input>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <template>
              <b-row>
                <b-col v-if="school.isPublic">
                  <validation-provider v-slot="validationContext" vid="district" name="District" rules="required">
                    <b-form-group label="District" label-for="district-input" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
                      <v-select id="district-input"
                                v-model="school.district"
                                :loading="options.districts.loading"
                                label="name"
                                :options="districts"
                                :reduce="option => option.id"
                                :selectable="option => option.state.enabled === true"
                                :filter="districtSearch"
                                :select-on-tab="true"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :disabled="district !== null"
                                class="w-100"
                                @input="onDistrictInput">

                        <template #option="{ name, state }">
                          <div class="d-flex justify-content-between align-items-center">
                            <div>
                              <h6 class="mb-0">{{ name.legal }}</h6>
                              <small>{{ name.popular }}</small>
                            </div>
                            <span v-if="!state.enabled" title="Disabled"><b-icon-lock-fill /></span>
                          </div>
                        </template>

                        <template #selected-option="{ name }">
                          {{ name.legal }}
                        </template>
                      </v-select>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col>
                  <validation-provider v-slot="validationContext" vid="zone" name="Zone" rules="required">
                    <b-form-group label="Zone" label-for="zone-input" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
                      <v-select id="zone-input"
                                v-model="school.zone"
                                :loading="options.zones.loading"
                                label="name"
                                :options="zones"
                                :reduce="option => option.id"
                                :select-on-tab="true"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :disabled="district !== null || school.district !== null"
                                class="w-100">
                      </v-select>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </template>

            <hr>

            <b-row>
              <b-col>
                <validation-provider v-slot="validationContext" vid="line1" name="Line 1" :rules="rules.address.line1">
                  <b-form-group label="Line 1" label-for="line1-input" :invalid-feedback="validationContext.errors[0]">
                    <b-form-input id="line1-input" v-model="school.address.line1" :state="getValidationState(validationContext)"></b-form-input>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col>
                <validation-provider v-slot="validationContext" vid="line2" name="Line 2" :rules="rules.address.line2">
                  <b-form-group label="Line 2" label-for="line2-input" :invalid-feedback="validationContext.errors[0]">
                    <b-form-input id="line2-input" v-model="school.address.line2" :state="getValidationState(validationContext)"></b-form-input>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <validation-provider v-slot="validationContext" vid="city" name="City" :rules="rules.address.city">
                  <b-form-group label="City" label-for="city-input" :invalid-feedback="validationContext.errors[0]">
                    <b-form-input id="city-input" v-model="school.address.city" :state="getValidationState(validationContext)"></b-form-input>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col>
                <validation-provider v-slot="validationContext" vid="county" name="County" :rules="rules.address.county">
                  <b-form-group label="County" label-for="county-input" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
                    <v-select id="county-input"
                              v-model="school.address.county"
                              label="county"
                              :reduce="option => option.county"
                              :options="counties"
                              :select-on-tab="true"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              class="w-100"/>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="auto">
                <validation-provider v-slot="validationContext" vid="state" name="State" :rules="rules.address.state">
                  <b-form-group label="State" label-for="state-input" :invalid-feedback="validationContext.errors[0]">
                    <state-input id="state-input" v-model="school.address.state" :validation-state="getValidationState(validationContext)" />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="auto">
                <validation-provider v-slot="validationContext" vid="zip-code" name="Zip Code" :rules="rules.address.zip">
                  <b-form-group label="Zip Code" label-for="zip-code-input" :invalid-feedback="validationContext.errors[0]">
<!--                    <b-form-input id="zip-code-input" v-model="school.address.zip" :state="getValidationState(validationContext)"></b-form-input>-->
                    <zip-input id="zip-code-input" v-model="school.address.zip" :state="getValidationState(validationContext)" />
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </form>
        </validation-observer>
      </b-container>
    </template>

    <!-- Footer -->
    <template #modal-footer>
      <b-row>
        <b-col align-self="center">
          <b-checkbox v-model="state.redirect" :checked="true" :unchecked-value="false">
            <small>Redirect to page on submit</small>
          </b-checkbox>
        </b-col>
        <b-col align-self="center" class="text-right">
          <b-button type="reset" class="mr-1" @click="clear()">Clear</b-button>
          <b-button type="submit" variant="primary" @click="$refs.observer.handleSubmit(create)">Submit</b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
  import vSelect from 'vue-select'
  import { uuid } from 'vue-uuid';
  import notify from '@/mixins/notify.mixin'
  import slugify from 'slugify';
  import {API, graphqlOperation} from 'aws-amplify';
  import { listDistricts, onUpdateDistrict, createSchool, listZones } from '@/graphql/queries/school-modal';
  import Avatar from '@/models/avatar';
  //import { rules } from '@/data/validation-rules'
  import StateInput from '@/components/StateInput.vue';
  import ZipInput from '@/components/ZipInput.vue';
  import Fuse from 'fuse.js';

  export default {
    name: 'SchoolModal',
    components: {
      ZipInput,
      StateInput,
      vSelect
    },
    mixins: [ notify ],
    props: {
      id: {
        type: String,
        required: true,
      },
      district: {
        type: Object,
        default: null
      }
    },
    data() {
      return {
        school: {
          isPublic: true,
          name: {
            legal: null,
            popular: null
          },
          beds: null,
          district: null,
          zone: null,
          address: {
            line1: null,
            line2: null,
            city: null,
            county: null,
            state: null,
            zip: null
          },
          staff: []
        },
        options: {
          districts: {
            items: [],
            loading: true,
            subscription: null
          },
          zones: {
            items: [],
            loading: true,
          },
          counties: {
            items: [],
            loading: true,
          }
        },
        rules: {
          address: {
            line1: { required: false },
            line2: { required: false },
            city: {
              required: false,
              alpha_spaces: true,
              max: 48
            },
            county: { required: false },
            state: {
              required: false,
              alpha: true,
              min: 2,
              max: 2
            },
            zip: {
              required: false,
              regex: /^\d{5}(?:[-\s]\d{4})?$/
            }
          },
          school: {
            beds: {
              required: true,
              numeric: true,
              min: 6,
              max: 12
            }
          }
        },
        state: {
          redirect: false
        },
      }
    },
    computed: {
      districts() {
        if(this.options.districts.loading) {
          this.listDistricts()
          return []
        }
        return this.options.districts.items
      },
      zones() {
        if(this.options.zones.loading) {
          this.listZones()
          return []
        }
        return this.options.zones.items
      },
      counties() {
        if(this.school.zone) {
          return this.options.counties.items.filter(county => county.zone === this.school.zone)
        }
        return this.options.counties.items
      }
    },
    async created() {
      if(this.district) {
        this.school.district = this.district.id
        this.school.zone = this.district?.zone?.id
      }
    },
    beforeDestroy() {
      if(this.options.districts?.subscription) { this.options.districts.subscription.unsubscribe() }
    },
    methods: {
      async create() {
        try {
          const id = uuid.v4();
          const slug = slugify(`${this.school.name.legal}-${this.school.beds}`, { lower: true })
          const input = {
            id: id,
            slug: slug,
            name: this.school.name,
            beds: this.school.beds,
            schoolZoneId: this.school?.zone,
            address: this.school.address,
            staff: this.school.staff,
            avatar: new Avatar(),
            state: {
              enabled: true
            }
          }

          if(this.school.district) {
            input.districtSchoolsId = this.school.district
          }

          const response = await API.graphql(graphqlOperation(createSchool, { input: input }));
          const school = response.data.createSchool

          if(this.state.redirect) {
            await this.$router.push({name: 'management-school', params: { id: id, slug: slug }})
          }
          else {
            await this.$emit('created', school)
            this.$root.$emit('bv::toggle::modal', this.id)
            this.notify({ title: 'Success', text: 'School was successfully created', icon: 'fas fa-server', variant: 'success' });
          }
        }
        catch (err) {
          console.error(err)
          this.notify({ title: 'Error', text: 'School failed to create', icon: 'fas fa-server', variant: 'danger'});
        }
      },

      async listZones(nextToken, pagedItems) {
        this.options.counties.loading = true

        const items = pagedItems || []
        const response = await API.graphql(graphqlOperation(listZones, { limit: 500, nextToken: nextToken }));

        items.push(...response.data.listZones.items)

        if(response.data.listZones.nextToken) {
          await this.listZones(response.data.listZones.nextToken, items)
        }
        else {
          this.options.zones.items = items.sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true }))
          this.options.zones.loading = false

          const counties = []
          items.forEach(item => {
            counties.push(...item.counties.map(county => ({ county, zone: item.id})))
          })

          this.options.counties.items = counties.sort((a, b) => a.county.localeCompare(b.county));
          this.options.counties.loaded = true
          this.options.counties.loading = false
        }
      },
      async listDistricts(nextToken, pagedDistricts) {
        this.options.districts.loading = true

        const districts = pagedDistricts || []
        const response = await API.graphql(graphqlOperation(listDistricts, { limit: 500, nextToken: nextToken }));

        districts.push(...response.data.listDistricts.items)

        if(response.data.listDistricts.nextToken) {
          await this.listDistricts(response.data.listDistricts.nextToken, districts)
        }
        else {
          this.options.districts.items = districts.sort((a, b) => a.name.legal.localeCompare(b.name.legal));
          this.options.districts.loading = false
        }
      },

      async onUpdateDistrict() {
        this.options.districts.subscription = API.graphql(graphqlOperation(onUpdateDistrict)).subscribe((sourceData) => {
          this.syncNotification()
          const district = sourceData.value.data.onUpdateDistrict
          if (district && this.options.districts.items.map(item => item.id).includes(district.id)) {
            this.options.districts.loading = true
            const index = this.options.districts.items.findIndex(item => item.id === district.id)
            if(index > -1) {
              this.options.districts.items.splice(index, 1, district)
            }
            this.options.districts.loading = false
          }
        });
      },

      onDistrictInput(value) {
        if(value) {
          this.school.zone = this.options.districts.items.find(district => district.id === value)?.districtZoneId
        }
        else {
          this.school.zone = null
        }
      },
      onIsPublicInput(value) {
        if(value === false) { this.school.district = null }
      },


      districtSearch(options, search) {
        const fuse = new Fuse(options, {
          keys: ['name.legal', 'name.popular'],
          shouldSort: true,
          threshold: 0.2
        })
        return search.length ? fuse.search(search).map(({ item }) => item) : fuse.list
      },

      clear() {
        this.school = {
          isPublic: true,
          name: {
            legal: null,
            popular: null
          },
          beds: null,
          district: null,
          zone: null,
          address: {
            line1: null,
            line2: null,
            city: null,
            county: null,
            state: null,
            zip: null
          },
          staff: []
        }
      },
      getValidationState({ dirty, validated, valid = null }) {
        return dirty || validated ? valid : null;
      },
    }
  }
</script>

<style scoped>

</style>
